// nuxt.config.ts
import * as process from 'process';
import { NuxtConfig } from '@nuxt/types';

// @ts-ignore
const nuxtConfig: NuxtConfig = {
  auth: {
    plugins: ['~/ts/plugins/auth.ts', '~/ts/plugins/vee-validate.ts', '~/ts/plugins/vue-slick-carousel.ts'],
    redirect: {
      login: '/connexion',
    },
    resetOnError: false,
    // kick the user if any error happens w/ the auth
    strategies: {
      apple: {
        clientId: process.env.APPLE_CLIENT_ID,
        endpoints: {
          authorization: 'https://appleid.apple.com/auth/authorize',
        },
        grantType: 'authorization_code',
        responseMode: 'form_post',
        responseType: 'code',
        scheme: 'oauth2',
        scope: ['name', 'email'],
      },
      facebook: {
        clientId: process.env.FACEBOOK_ID,
        endpoints: {
          userInfo: 'https://graph.facebook.com/v6.0/me?fields=id,name,picture{url}',
        },
        scope: ['public_profile', 'email'],
      },
      google: {
        clientId: process.env.GOOGLE_CLIENT_ID,
        codeChallengeMethod: '',
        responseType: 'token id_token',
      },
      local: {
        endpoints: {
          login: { method: 'post', url: '/api/login_check' },
          logout: false,
          refresh: { method: 'post', url: '/api/login_check' },
          user: { method: 'get', url: '/api/me' },
        },
        refreshToken: {
          data: 'refresh_token',
          maxAge: 60 * 60 * 24 * 30,
          property: 'refresh_token',
          required: true,
        },
        scheme: 'refresh',
        token: {
          globalToken: true,
          maxAge: 3600,
          property: 'id_token',
          type: 'Bearer',
        },
        user: {
          autoFetch: true,
          property: '',
        },
      },
    },
  },

  axios: {
    baseURL: process.env.BASE_URL_API || 'http://localhost',
    headers: {
      common: {
        'Content-Type': 'application/ld+json',
      },
    },
  },

  // Build Configuration: https://go.nuxtjs.dev/config-build
  build: {
    babel: {
      plugins: [
        [
          'component',
          {
            libraryName: 'maz-ui',
            styleLibraryName: 'css',
          },
        ],
      ],
    },
    extend(config, { isClient }) {
      // Extend only webpack config for client-bundle
      if (isClient) {
        config.devtool = 'source-map';
      }
    },
    html: {
      minify: {
        collapseBooleanAttributes: true,
        collapseWhitespace: true,
        decodeEntities: true,
        minifyCSS: true,
        minifyJS: true,
        minifyURLs: true,
        preserveLineBreaks: false,
        processConditionalComments: true,
        removeComments: true,
        removeEmptyAttributes: true,
        removeEmptyElements: true,
        removeRedundantAttributes: true,
        trimCustomFragments: true,
        useShortDoctype: true,
      },
    },
    postcss: {
      postcssOptions: {
        plugins: {
          autoprefixer: {},
          cssnano:
            process.env.NODE_ENV === 'production'
              ? {
                preset: [
                  'default',
                  {
                    discardComments: {
                      removeAll: true,
                    },
                  },
                ],
              }
              : false,
          tailwindcss: {}, // disable cssnano when not in production
        },
      },
    },
    transpile: ['vee-validate/dist/rules', 'tailwind-merge', 'defu'],
  },


  googleAnalytics: {
    id: process.env.GOOGLE_ANALYTICS_ID, // Use as fallback if no runtime config is provided
  },


  // Modules for dev and build (recommended): https://go.nuxtjs.dev/config-modules
  buildModules: ['@nuxt/typescript-build', '@nuxtjs/tailwindcss', '@nuxtjs/moment', '@nuxtjs/google-analytics'],

  // Auto import components: https://go.nuxtjs.dev/config-components
  components: true,

  css: [
    '@/assets/css/main.css',
    'vue-slick-carousel/dist/vue-slick-carousel.css',
    'vue-slick-carousel/dist/vue-slick-carousel-theme.css',
  ],

  dev: process.env.NODE_ENV !== 'production',

  env: {
    baseURL: process.env.BASE_URL_API || 'http://localhost',
    clientBaseURL: process.env.BASE_URL || 'http://localhost:3000',
    geoapifyKey: process.env.GEOAPIFY_API_KEY || "",
  },

  // Global page headers: https://go.nuxtjs.dev/config-head
  gtag: {
    id: 'G-87JTGMRH86',
  },

  head: {
    __dangerouslyDisableSanitizers: ['script'],
    htmlAttrs: {
      lang: 'en',
    },
    link: [{ href: '/favicon.ico', rel: 'icon', type: 'image/x-icon' }],
    meta: [
      { charset: 'utf-8' },
      { content: 'width=device-width, initial-scale=1, minimum-scale=1', name: 'viewport' },
      { content: 'telephone=no', name: 'format-detection' },
      { content: 'hCAcHgLDjtjZm3qhGRiYEGIvk0QLcZtmyeh2fX5aQrU', name: 'google-site-verification' },
    ],
    script: [
      {
        async: true,
        hid: 'google-analytics',
        src: `https://www.googletagmanager.com/gtag/js?id=${process.env.GOOGLE_ANALYTICS_ID}`,
      },
      {
        async: true,
        crossOrigin: 'anonymous',
        hid: 'google-syndication',
        src: `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${process.env.GOOGLE_SYNDICATION_CLIENT}`,
      },
      {
        innerHTML: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag("js", new Date());
          gtag("config", "${process.env.GOOGLE_ANALYTICS_ID}");
        `,
      },
      {
        innerHTML: `
          window.axeptioSettings = {
            clientId: "${process.env.AXEPTIO_ID}",
            cookiesVersion: "caramaps-fr",
            googleConsentMode: {
              default: {
                analytics_storage: "denied",
                ad_storage: "denied",
                ad_user_data: "denied",
                ad_personalization: "denied",
                wait_for_update: 500
              }
            }
          };
          (function(d, s) {
            var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
            e.async = true; e.src = "//static.axept.io/sdk.js";
            t.parentNode.insertBefore(e, t);
          })(document, "script");
      `,
      },
    ],
    title: 'Caramaps - Annuaire de Campings, Aires de service, Accueil à la ferme.',
  },

  // Modules: https://go.nuxtjs.dev/config-modules
  i18n: {
    baseUrl: process.env.BASE_URL || 'http://localhost:3000',
    defaultLocale: 'fr',
    detectBrowserLanguage: {
      alwaysRedirect: true,
      cookieKey: 'i18n_redirected',
      useCookie: true,
    },
    langDir: 'translations/',
    lazy: true,
    locales: [
      {
        code: 'fr',
        file: 'translations.ts',
        iso: 'fr-FR',
        localeImg: 'fr.svg',
        name: 'Français',
      },
      {
        code: 'en',
        file: 'translations.ts',
        iso: 'en-GB',
        localeImg: 'gb.svg',
        name: 'Anglais',
      },
      {
        code: 'es',
        file: 'translations.ts',
        iso: 'es-ES',
        localeImg: 'es.svg',
        name: 'Espagnol',
      },
      {
        code: 'it',
        file: 'translations.ts',
        iso: 'it-IT',
        localeImg: 'it.svg',
        name: 'Italien',
      },
      {
        code: 'de',
        file: 'translations.ts',
        iso: 'de-DE',
        localeImg: 'de.svg',
        name: 'Allemand',
      },
      {
        code: 'nl',
        file: 'translations.ts',
        iso: 'nl-NL',
        localeImg: 'nl.svg',
        name: 'Néerlandais',
      },
      {
        code: 'pt',
        file: 'translations.ts',
        iso: 'pt-PT',
        localeImg: 'pt.svg',
        name: 'Portugais',
      },
    ],
    // @ts-ignore
    seo: true,
    strategy: 'prefix_except_default',
    vueI18n: {
      fallbackLocale: 'fr',
    },
  },

  modules: [
    '@nuxtjs/axios',
    '@nuxtjs/auth-next',
    '@nuxtjs/i18n',
    '@nuxtjs/moment',
    '@nuxtjs/sitemap',
    '~/modules/seo.ts'
  ],

  moment: {
    defaultLocale: 'fr',
    defaultTimezone: 'Europe/Paris',
    locales: ['fr', 'it', 'de', 'nl', 'es', 'pt'],
    plugin: true,
  },

  // Plugins to run before rendering page: https://go.nuxtjs.dev/config-plugins
  plugins: [
    { src: '~/ts/plugins/axios.ts' },
    { src: '~/ts/plugins/filepond.ts' },
    { src: '~/ts/plugins/gmap.ts' },
    { src: '~/ts/plugins/moment.ts' },
    { src: '~/ts/plugins/maz-ui' },
    { src: '~/ts/plugins/toast.ts' },
    { src: '~/ts/plugins/route.ts' },
  ],

  preproduction: process.env.PREPRODUCTION === 'true',

  privateRuntimeConfig: {
    facebookID: process.env.FACEBOOK_ID || 'FACEBOOK_ID',
    facebookToken: process.env.FACEBOOK_KEY || 'FACEBOOK_KEY',
    facebookUser: process.env.FACEBOOK_USERNAME || 'FACEBOOK_USERNAME',
  },

  publicRuntimeConfig: {
    baseURL: process.env.BASE_URL_API || 'http://localhost',
    clientBaseURL: process.env.BASE_URL || 'http://localhost:3000',
    googleAnalytics: {
      id: process.env.GOOGLE_ANALYTICS_ID,
    },
  },

  router: {
    middleware: ['auth'],
  },

  server: {
    host: process.env.HOST || 'localhost',
    port: process.env.SERVER_PORT || 3000,
  },

  sitemap: {
    hostname: process.env.BASE_URL || 'http://localhost',
  },

  ssr: true,

  watchers: {
    webpack: {
      ignored: ['**/*.{md,log,prettierignore,prettierrc,gitignore}', '**/.{git,idea}/*'],
    },
  },
};

export default nuxtConfig;
