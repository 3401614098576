import { render, staticRenderFns } from "./default.vue?vue&type=template&id=66e96aed&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheHeader: require('/home/caramaps/site/dvp/www2/releases/20241023140239/components/TheHeader.vue').default,BaseToast: require('/home/caramaps/site/dvp/www2/releases/20241023140239/components/base/BaseToast.vue').default,TheFooter: require('/home/caramaps/site/dvp/www2/releases/20241023140239/components/TheFooter.vue').default,AdPopup: require('/home/caramaps/site/dvp/www2/releases/20241023140239/components/AdPopup.vue').default})
